<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="payment-setting-wrapper">
          <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
            <h4 class="">Payment/List</h4>
          </div>

          <div class="accordion" id="appointmentAccordion">

            <div class="card">
              <div class="card-header" id="headingOnlineAppointmentTimebaseSurcharge">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseOnlineAppointmentTimebaseSurcharge" aria-expanded="false"
                        aria-controls="collapseOnlineAppointmentTimebaseSurcharge">

                  <span class="text-capitalize payment-setting-title">Simplify Payment gateway 3DS status</span>

                </button>
              </div>
              <div id="collapseOnlineAppointmentTimebaseSurcharge" class="collapse"
                   aria-labelledby="headingOnlineAppointmentTimebaseSurcharge" data-parent="#appointmentAccordion">
                <div class="card-body">
                  <SettingPaymentSimplifyGateway3dsStatus
                      v-if="this.settingPaymentSimplifyGateway3DSStatus?.value"
                      :payment-simplify-gateway3ds-status="this.settingPaymentSimplifyGateway3DSStatus"/>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue';
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import {
  mapActions,
  mapGetters
} from 'vuex';
import SettingPaymentSimplifyGateway3dsStatus
  from "@/views/backEnd/settings/payment/includes/SettingPaymentSimplifyGateway3dsStatus.vue";

export default {
  name: "SettingPaymentList",
  components: {
    AppLayout,
    SettingPaymentSimplifyGateway3dsStatus,

  },
  mixins: [ShowToastMessage, Loader],
  data() {
    return {
      getSettingsParams: {
        type: ["payment"],

        key: [
          "payment_simplify_gateway_3ds_status",
        ],
      },
      currentState: {},
      frontEndErrors: [],
    }
  },

  computed: {
    ...mapGetters({
      settingPaymentSimplifyGateway3DSStatus: "appSettings/settingPaymentSimplifyGateway3DSStatus",
    }),

  },

  methods: {
    ...mapActions({

      getSettings: "appSettings/getSettings",
      putSetting: "appSettings/putSetting",

    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.loader(false);
  },

}
</script>

<style>
.payment-setting-wrapper .accordion .card {
  overflow: visible !important;
}

.payment-setting-title {
  font-size: 16px;
  color: rgb(44, 44, 44);
}
</style>
