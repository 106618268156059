<template>
  <div>
    <div class="px-2">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <div class="controls">
              <label>Current Status</label>
              <div style="margin-top: .5rem">
                <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                  <div>
                    <input v-model="gatewayCheckBoxStatus" type="checkbox" class="custom-control-input" checked
                           :id="currentSetting.key">
                    <label class="custom-control-label mr-1" :for="currentSetting.key">
                      <span class="switch-icon-left"><i class="bx bx-check"></i></span>
                      <span class="switch-icon-right"><i class="bx bx-x"></i></span>
                    </label>
                  </div>
                  <span class="font-medium-1 text-bold-600">{{
                      gatewayCheckBoxStatus === true ? "Active" : "Inactive"
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-1">
        <div v-for="(gateway,gatewayKey, listIndex) in gatewayStatusList" :key="listIndex"
             class="col-12 border rounded border-light mb-1">
          <div class="d-md-flex w-100 align-items-center">

            <div class="col-12 col-lg-4 col-md-4 rate-input-class">
              <h4>{{ allCaseToTitle(gatewayKey) }}</h4>
            </div>


            <div v-for="(gate,gateIndex) in gateway" :key="gateIndex" class="col-12 col-lg-3 col-md-3 rate-input-class">
              <div class="form-group">
                <div class="controls">
                  <label class="mt-1"> {{ gate.type }}</label>
                  <div style="margin-top: .6rem">
                    <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center"
                         :class="switchColor[listIndex]">
                      <div>
                        <input v-model="detailsStatus[listIndex][gatewayKey][gateIndex]" type="checkbox"
                               class="custom-control-input" checked
                               :id="`gatewayType_${gatewayKey}_${gateIndex}`">
                        <label class="custom-control-label mr-1" :for="`gatewayType_${gatewayKey}_${gateIndex}`">
                          <span class="switch-icon-left"><i class="bx bx-check"></i></span>
                          <span class="switch-icon-right"><i class="bx bx-x"></i></span>
                        </label>
                      </div>
                      <span class="font-medium-1 text-bold-600">{{
                          detailsStatus[listIndex][gatewayKey][gateIndex] ? "Active" : "Inactive"
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="d-flex w-100 align-items-center justify-content-end px-2">
          <div>
            <button @click="updateSettingsHandler" class="btn btn-primary " type="submit">Update</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import GlobalMixin from "@/components/backEnd/mixins/GlobalMixin";


export default {
  name: "SettingPaymentSimplifyGateway3dsStatus",
  mixins: [ShowToastMessage, Loader, GlobalMixin],
  props: ['paymentSimplifyGateway3dsStatus'],
  data() {
    return {
      currentSetting: this.paymentSimplifyGateway3dsStatus,
      gatewayCheckBoxStatus: 0,
      detailsStatus: [],
      gatewayStatusList: {},
      switchColor: [
        'custom-switch-success',
        'custom-switch-info',
        'custom-switch-danger',
        'custom-switch-warning',
        'custom-switch-dark',
      ],
    };
  },

  computed: {
    isUpdateButtonEnable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null;
    }
  },
  watch: {
    'currentSetting.value': {
      handler(newValue) {
        this.gatewayCheckBoxStatus = newValue.status == 1;
        this.gatewayStatusList = newValue.details;
      },
      deep: true,
      immediate: true
    },
    gatewayStatusList: {
      handler(newValue) {
        Object.entries(newValue).forEach((entry) => {
          const [key, value] = entry;
          const state = {};
          state[key] = value.map((item) => item.status == 1);
          this.detailsStatus.push(state)
        });
      },
    },
    detailsStatus: {
      handler(newValue) {
        newValue.forEach((item) => {
          Object.entries(item).forEach((entry) => {
            const [key, value] = entry;
            value.forEach((status, index) => {
              this.gatewayStatusList[key][index]['status'] = status === true ? 1 : 0;
            })
          });
        })
      },
      deep: true,
      immediate: true
    },
    gatewayCheckBoxStatus(appStatus) {
      this.currentSetting.value.status = (appStatus === true ? 1 : 0);
    }
  },

  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),

    randomNumber(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },

    async updateSettingsHandler() {
      await this.updateSettings();
    },

    async updateSettings() {
      let dataObj = {
        id: this.currentSetting.id,
        data: {
          type: this.currentSetting.type,
          key: this.currentSetting.key,
          value: {'status': this.currentSetting.value.status, 'details': {...this.gatewayStatusList}},
        }
      };

      console.log(dataObj);
      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Simplify gateway payment status updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    }
  },
}
</script>

<style scoped>
.rate-input-class {
  margin-bottom: 38px;
}

.cross-button-mrg-style {
  margin: 0px 0px 38px 0px;
}
</style>